class SplitContent {

	constructor() {
		this.blocks = document.querySelectorAll('.block-split-content');
		this.init();
	}

	init() {
		this.blocks.forEach((block) => {
			this.initSingle(block);
		});
	}

	initSingle(block){

	}
}

new SplitContent();
